import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import Layout from '../src/components/Layout/Layout';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Modi from '../src/components/Modi/Modi';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Hod from '../src/components/Hod/Hod';

import Tyr from '../src/components/Tyr/Tyr';
import Ymir from '../src/components/Ymir/Ymir';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import { Freya } from '../src/components/Freya/Freya';
import { generatedBreadCrumb } from '../src/helpers/breadCrumbs';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';
import Astrid from '../src/components/Astrid/Astrid';
import Gerd from '../src/components/Gerd/Gerd';
import Frigg from '../src/components/Frigg/Frigg';
import AnounceCovid from '../src/components/AnounceCovid/AnounceCovid';
import SingleParagraphData from '../src/components/Auto/SingleParagraph/SingleParagraph';

import './ficha_parques.scss';

class ParkDetails extends React.Component {
  state = {
    showModal: false,
    isFLVideo: true, // TRUE IF FL VIDEO
  };

  halloweenText = tt(
    'A partir del 24 de septiembre en PortAventura Park, prepárate para Halloween, prepárate para una celebración donde reinarán el misterio y la emoción. Recorre los pasajes más tenebrosos, descubre cómo los personajes del resort celebran el Halloween más familiar o degusta la comida más escalofriante en una exquisita velada. ¡Las sorpresas estarán aseguradas este Halloween en PortAventura Park! Del 24 de septiembre al 13 de noviembre 2022, no te pierdas cómo PortAventura Park se viste de Halloween y transforma el ambiente del parque temático en un sitio tenebroso apto para todos los públicos. ¡Pásatelo de miedo en el Halloween de PortAventura Park!',
    this.props.pageContext.locale
  );

  prepareForYmir = () => {
    const dc = this.props.data.allAdditionalLinks2.edges[0].node.cards;
    let scheduleType;
    switch (this.props.pageContext.locale) {
      case 'es':
        scheduleType = 'horarios-calendario';
        break;
      case 'fr':
        scheduleType = 'horaires-calendrier';
        break;
      case 'ca':
        scheduleType = 'horaris-calendari';
        break;
      case 'en':
        scheduleType = 'dates-times';
        break;
      case 'de':
        scheduleType = 'oeffnungszeiten-kalender';
        break;
      case 'it':
        scheduleType = 'orari-calendari';
        break;
      case 'ru':
        scheduleType =
          '%D1%80%D0%B0%D1%81%D0%BF%D0%B8%D1%81%D0%B0%D0%BD%D0%B8%D0%B5-%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D0%BA';
        break;
      default:
        scheduleType = 'horarios-calendario';
    }

    if (
      this.props.pageContext.park_name === 'PortAventura' ||
      this.props.pageContext.park_name === 'Halloween'
    ) {
      const ret2 = [
        {
          title: dc._0.title,
          buttonCard: dc._0.buttonCard,
          link: dc._0.link,
          icon: dc._0.icon,
        },
        {
          title: dc._1.title,
          buttonCard: dc._1.buttonCard,
          link: dc._1.link,
          icon: dc._1.icon,
        },
        {
          title: dc._2.title,
          buttonCard: dc._2.buttonCard,
          link: dc._2.link,
          icon: dc._2.icon,
        },
      ];
      return ret2;
    }
    const ret2 = [
      {
        title: dc._0.title,
        buttonCard: dc._0.buttonCard,
        link: `${this.props.pageContext.options[0]}/${scheduleType}`,
        icon: dc._0.icon,
      },
      {
        title: dc._1.title,
        buttonCard: dc._1.buttonCard,
        link: dc._1.link,
        icon: dc._1.icon,
      },
      {
        title: dc._2.title,
        buttonCard: dc._2.buttonCard,
        link: dc._2.link,
        icon: dc._2.icon,
      },
    ];
    return ret2;
  };

  handleMap = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  render() {
    const { pageContext } = this.props;
    const heimdallData = {
      name: this.props.data.allFichaParquesTitleBlock.edges[0].node.title,
      image: {
        url: this.props.data.allFichaParquesBlockImageInfoGeneral.edges[0].node.localImage
          .childImageSharp.fluid,
      },
      imageMobile: {
        url: this.props.data.allFichaParquesBlockMobileImageInfoGeneral.edges[0].node.localImage
          .childImageSharp.fluid,
      },
      map: 'true',
    };
    const buttonCTAlocale = () => {
      let buttonLocale;
      if (this.props.pageContext.url.includes('aquatic')) {
        buttonLocale = {
          cta: tt('/entradas/caribe-aquatic-park', this.props.pageContext.locale),
          size: 'alone',
          color: 'orange',
          ctaText: tt('COMPRAR ENTRADAS', this.props.pageContext.locale),
        };
      } else if (this.props.pageContext.url.includes('ferrari')) {
        buttonLocale = {
          cta: tt('/entradas/ferrari-land', this.props.pageContext.locale),
          size: 'alone',
          color: 'orange',
          ctaText: tt('COMPRAR ENTRADAS', this.props.pageContext.locale),
        };
      } else if (this.props.pageContext.url.includes('portaventura')) {
        buttonLocale = {
          cta: tt('/entradas', this.props.pageContext.locale),
          size: 'alone',
          color: 'orange',
          ctaText: tt('COMPRAR ENTRADAS', this.props.pageContext.locale),
        };
      }
      return buttonLocale;
    };

    const modiData = {
      Image:
        pageContext.park_name === 'PortAventura' || pageContext.park_name === 'Halloween'
          ? 'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/009/030/original/pap.png'
          : pageContext.park_name === 'FerrariLand'
          ? 'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/009/031/original/fl.png'
          : pageContext.park_name === 'AcuaticPark'
          ? 'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/009/032/original/cap.png'
          : null,
      Text:
        this.props.data.allFichaParquesTitleBlock.edges[0].node.subTitle ||
        (pageContext.park_name === 'Halloween' ? this.halloweenText : ''),
      link: this.props.data.allFichaParquesTitleBlock.edges[0].node.description,
      button1: buttonCTAlocale(),
    };

    const h2Data = {};
    if (this.props.pageContext.park_name === 'Halloween') {
      h2Data.title = this.props.data.allFichaParquesCardsTitle.edges[0].node.title;
    } else {
      h2Data.title = this.props.data.allFichaParquesCardsTitle.edges[0].node.name;
    }

    const tyrData = {
      title: this.props.data.allFichaParquesCardsTitle.edges[0].node.name,
      cards: this.props.data.allParquesCardsSubBlock.edges.map((block) => ({
        MainImage: block.node.localImage.childImageSharp.fluid,
        Image: block.node.icon,
        altImage: block.node.image_alt,
        Title: block.node.title,
        TitleURL: block.node.button1.cta,
        Text: block.node.description,
        button1: {
          cta: block.node.button1.cta,
          size: block.node.button1.size,
          color: block.node.button1.color,
          ctaText: block.node.button1.ctaText,
        },
        button2: {
          cta: block.node.button2.cta,
          size: block.node.button2.size,
          color: block.node.button2.color,
          ctaText: block.node.button2.ctaText,
        },
      })),
    };

    const aesirData = {
      breadcrumbs: generatedBreadCrumb(
        this.props.data.allFichaParquesBreadCrumb.edges[0].node.destination_url,
        this.props.data.allFichaParquesBreadCrumb.edges[0].node.name
      ),
      pawURL: tt('/', this.props.pageContext.locale),
    };

    const ymirData = {
      cards: this.prepareForYmir(),
    };

    const friggData = {
      videoUrl:
        "https://portaventura-staging-files.s3-eu-west-1.amazonaws.com/pawnew/Video+New+PAW+-+optimizated/FerrariLand'19_2.mp4",
      imageMobile: this.props.data.allFichaParquesBlockMobileImageInfoGeneral.edges[0].node
        .localImage.childImageSharp.fluid,
      name: this.props.data.allFichaParquesTitleBlock.edges[0].node.title,
    };

    const freyaData = {
      title: tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale),
      cards: this.props.data.allFichaParquesPromosSubBlock.edges.map((item) => ({
        image: item.node.localImage.childImageSharp.fluid,
        altImage: item.node.image_alt,
        title: item.node.title,
        imgsize: 'promoted',
        buttons: {
          cta: item.node.buttons.cta,
          size: item.node.buttons.size,
          color: item.node.buttons.color,
          ctaText: item.node.buttons.ctaText,
        },
      })),
    };

    const halloweenInfo = [
      {
        title: tt(
          'Halloween en el parque de atracciones PortAventura Park',
          this.props.pageContext.locale
        ),
        titleTag: 'H3',
        titleStyle: 'H5',
        titleAlign: 'left',
        body: `<p>${tt(
          'Pocas celebraciones en el año crean tanta expectación como lo hace esta fecha del calendario. Pero como no podíamos ofrecerte un único día para celebrarlo a lo grande, dispones de varias semanas para una visita escalofriante. ¿Por qué disfrutar de una fiesta tan esperada en una sola noche cuando puedes hacerlo durante mucho más tiempo?',
          this.props.pageContext.locale
        )}</p>
        <p>${tt(
          'Halloween en el parque de atracciones de PortAventura Park equivale a adentrarse en una aventura de lo más tenebrosa. Si eres amante del terror y quieres pasar un rato cargado de emociones fuertes, ¡aprovecha y ven al parque en esta temporada!',
          this.props.pageContext.locale
        )}</p>`,
      },
      {
        title: tt(
          'Disfruta de actividades y espectáculos exclusivos por Halloween en el parque de atracciones PortAventura Park',
          this.props.pageContext.locale
        ),
        titleTag: 'H3',
        titleStyle: 'H5',
        titleAlign: 'left',
        body: `<p>${tt(
          'Para que esta fiesta no se parezca a ninguna otra en la que hayas estado antes, Halloween en el parque de atracciones PortAventura Park te ha preparado toda una selección especial de actividades y espectáculos relacionados con la temática. Y para que nadie se asuste en exceso, ¡tenemos opciones aptas para toda la familia!',
          this.props.pageContext.locale
        )}</p>
        <p>${tt(
          '¡Ven a pasar unos días irrepetibles y cargados de sorpresas en esta nueva temporada de Halloween en el parque de atracciones PortAventura Park!',
          this.props.pageContext.locale
        )}</p>`,
      },
    ];

    return (
      <Layout layoutFromOld 
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allFichaParquesPromosSeoData.edges[0].node._0.title}
          description={this.props.data.allFichaParquesPromosSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        {/* {this.props.pageContext.park_name === 'FerrariLand' ? (
          <MediaServerRender predicted="mobile" hydrated>
            <MediaMatcher
              mobile={<Heimdall data={heimdallData} handleMap={this.handleMap} />}
              tablet={<Heimdall data={heimdallData} handleMap={this.handleMap} />}
              desktop={<Frigg data={friggData} />}
            />
          </MediaServerRender>
        ) :  */}
        {/* } */}
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} handleMap={this.handleMap} />
          <div className="general-index">
            <div className={`parks-content ${this.state.isFLVideo === true ? 'pcl' : ''}`}>
              <Modi data={modiData} />
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                options={this.props.pageContext.options}
                title={this.props.data.allFichaParquesBreadCrumb.edges[0].node.name}
              />
              <Hod
                data={this.props.data.allFichaParquesRichData.edges[0].node.rich_markup}
                url={this.props.pageContext.url}
                img={
                  this.props.data.allFichaParquesBlockImageInfoGeneral.edges[0].node.localImage
                    .childImageSharp.fluid
                }
              />
              <BlueArrow />
              {/* {this.props.pageContext.park_name === 'AcuaticPark' ? (
                <AnounceCovid
                  text={tt(
                    'PortAventura Caribe Aquatic Park permanecerá cerrado.',
                    this.props.pageContext.locale
                  )}
                />
              ) : null} */}
              <Astrid
                pageContext={this.props.pageContext}
                handleMap={this.handleMap}
                modal={this.state.showModal}
              />
              <H2 data={h2Data} />
              <Tyr data={tyrData} />
              {pageContext.park_name === 'Halloween' &&
                halloweenInfo.map((info) => (
                  <SingleParagraphData
                    title={info.title}
                    titleTag={info.titleTag}
                    titleStyle={info.titleStyle}
                    body={info.body}
                  />
                ))}
              <Ymir data={ymirData} />
              {pageContext.park_name === 'Halloween' ? undefined : (
                <Gerd pageContext={this.props.pageContext} modal={this.state.showModal} />
              )}
              <div className="promotions-content">
                <H4 title={tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale)} />
                <Freya data={freyaData} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint-disable react/require-default-props */
ParkDetails.propTypes = {};
/* eslint-enable react/require-default-props */

export default ParkDetails;

export const pageQuery = graphql`
  query allFichaParques($park_name: String!, $locale: String!) {
    allFichaParquesPromosSeoData(filter: { tag: { eq: $park_name }, lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allFichaParquesBlockImageInfoGeneral(
      filter: { tag: { eq: $park_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    allFichaParquesBlockMobileImageInfoGeneral(
      filter: { tag: { eq: $park_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    allFichaParquesTitleBlock(filter: { tag: { eq: $park_name }, lang: { eq: $locale } }) {
      edges {
        node {
          title
          subTitle
          description
        }
      }
    }
    allFichaParquesCardsTitle(filter: { tag: { eq: $park_name }, lang: { eq: $locale } }) {
      edges {
        node {
          name
          title
        }
      }
    }
    allParquesCardsSubBlock(filter: { tag: { eq: $park_name }, lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          icon
          button1 {
            cta
            size
            color
            ctaText
          }
          button2 {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allFichaParquesPromosSubBlock(
      filter: { tag: { eq: $park_name }, lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          title
          description
          imgsize
          stars
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }

    allAdditionalLinks2(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          id
          cards {
            _0 {
              title
              buttonCard
              link
              icon
            }
            _1 {
              title
              buttonCard
              link
              icon
            }
            _2 {
              title
              buttonCard
              link
              icon
            }
          }
        }
      }
    }
    allFichaParquesBreadCrumb(filter: { tag: { eq: $park_name }, lang: { eq: $locale } }) {
      edges {
        node {
          tag
          destination_url
          name
        }
      }
    }
    allFichaParquesRichData(filter: { tag: { eq: $park_name }, lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
          }
        }
      }
    }
  }
`;
