import './frigg.scss';

import React from 'react';
import ReactPlayer from 'react-player';
import { injectIntl } from 'react-intl';

class Frigg extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const {
      data: { videoUrl, name },
    } = this.props;

    return (
      <div className="frigg-container">
        <div className="index-container">
          <div className="player-wrapper">
            <div className="name-container">
              <span>{name}</span>
            </div>
            <ReactPlayer
              className="react-player"
              url={videoUrl}
              playing
              forceVideo
              width="100%"
              height="105%"
              preload
              volume={1}
              muted
              loop
              controls={false}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default injectIntl(Frigg);
